import { useLottie } from "lottie-react";
import logo from "../assets/logo.svg";
import homeAnimation from "../assets/home-animation.json";
import { useState } from "react";
import "./index.css";
import { Toaster, toast } from "sonner";
import { useLocalStorage, useWindowSize } from "usehooks-ts";
import Confetti from "react-confetti";
import { useHotkeys } from "react-hotkeys-hook";

export function App() {
  const { width, height } = useWindowSize();
  const [email, setEmail] = useState("");
  const [hasRequestedAccess, setHasRequestedAccess] = useLocalStorage(
    "hasRequestedAccess",
    "false"
  );

  const options = {
    animationData: homeAnimation,
    loop: true,
  };

  const { View } = useLottie(options);

  const requestAccess = async (e) => {
    const hubspotPortalId = "22033000";
    const hubspotFormId = "9e240216-0c3e-405f-9aa2-e8b4f3d0ab88";
    const endpoint = `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${hubspotFormId}`;

    const payload = {
      fields: [
        {
          name: "email",
          value: email,
        },
      ],
      context: {},
    };

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      if (response.ok) {
        setHasRequestedAccess(true);
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error requesting access, try again.");
    }
  };

  const joinDiscord = () => {
    if (hasRequestedAccess) {
      window.open("https://discord.gg/bDVYvG3Czd", "_blank");
    }
  };

  useHotkeys("d", joinDiscord, [hasRequestedAccess]);

  return (
    <div className="app">
      {hasRequestedAccess !== "false" && (
        <Confetti width={width} height={height} recycle={false} />
      )}
      <div className="grunge-underlay"></div>
      <Toaster />
      <div className="app-content">
        <div className="logo-container">
          <img src={logo} className="logo" alt="logo" />
        </div>

        <h1>Launch your next idea in days, not weeks.</h1>
        <p>AI-assisted tech stack assembly</p>

        {hasRequestedAccess === "false" ? (
          <div className="request-access-container">
            <input
              type="email"
              placeholder="Your email"
              value={email}
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  requestAccess();
                }
              }}
            />
            <div className="button" onClick={requestAccess}>
              <div className="button-text">Request Early Access</div>
              <div className="button-content">{View}</div>
            </div>
          </div>
        ) : (
          <>
            <div
              style={{
                minHeight: "70px",
                width: "100%",
                border: "1px solid",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                padding: "0.5rem 1rem",
                justifyContent: "space-between",
              }}
            >
              <div>🎉 You've requested access! We'll be in touch. </div>
              <a
                className="button small"
                href="https://discord.gg/bDVYvG3Czd"
                target="_blank"
                rel="noreferrer"
              >
                <span
                  className="hotkey"
                  style={{
                    opacity: 0.5,
                    marginRight: "0.5rem",
                    fontSize: "0.75rem",
                    backgroundColor: "#000",
                    padding: "0.25rem",
                    borderRadius: "6px",
                    marginLeft: "-0.3rem",
                  }}
                >
                  Press D
                </span>
                Join Discord
              </a>
            </div>
          </>
        )}
        <div style={{ opacity: 0.35, width: "100%", marginTop: "2rem" }}>
          Made by{" "}
          <a href="https://chandlerroth.com/" style={{ color: "#fff" }}>
            Chandler Roth
          </a>{" "}
          and the team at{" "}
          <a href="https://enok.co" style={{ color: "#fff" }}>
            Enok
          </a>
        </div>
      </div>
    </div>
  );
}
