import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { PostHogProvider } from "posthog-js/react";

ReactDOM.render(
  <React.StrictMode>
    <PostHogProvider apiKey="phc_fceroRzoWFT9PJxsVQV6rnMJuWqQzQuXpY05hLFSCVm">
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PostHogProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
